import React from 'react';
import AboutComp from '../Components/AboutComp';
import ServicesComp from '../Components/ServicesComp';
import CareersComp from '../Components/CareersComp';
import VideoWraper from '../Components/VideoContent';
import FooterComp from '../Components/Footer';
import CarouselContainer from '../Components/Carousel';
import PartnerBrands from '../Components/PartnerBrands';
import CustomerCounters from '../Components/SuccessCounter';

const Home = ({theme}) => {
    return (
        <>
        
            <VideoWraper />
            {/* <CarouselContainer/> */}
            <AboutComp />
            <ServicesComp />
            <CareersComp />
            <CustomerCounters />
            <PartnerBrands />
            <FooterComp />
        </>
    )
}

export default Home