import React from 'react';
import MenuAppBar from '../../Components/Menu';
import { Container, Grid } from '@mui/material';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { JasvikExpertise } from '../../Components/JasvikContent';
import { ArrowCircleRight } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import FooterComp from '../../Components/Footer';

const JasvikMicrosoftComp = ({ data }) => {
    // const { id } = useParams()
    // const page = JasvikMicrosoft.find(page => page.id === parseInt(id))

    // if (!data || !data.title || !data.info || !data.bullets) {
    //     // Return a message or placeholder if data is not available
    //     return <div>No data available</div>;
    // }
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={2}>
                    <Grid item  md={8} xs={12} mt={{ xs: 1, sm: 1, md: 13, lg: 13 }}>
                        <div className='mb50 text-left'>
                            <h1>{data.title}</h1>
                            {data.info && (
                                <div>
                                    {data.info.map((infoItem, infoIndex) => (
                                        <div key={infoIndex} style={{marginBottom: '15px'}}>
                                            <Typography variant="body1">{infoItem.p1}</Typography>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div>
                                {data.h3 && <h3 className='red-text'>{data.h3}</h3>}

                                {data.bullets && (
                                    <div>
                                        {data.bullets.map((bullet, bulletIndex) => (
                                            <div key={bulletIndex}>
                                                <h2 className='red-text'>{bullet.h1}</h2>
                                                <List className='faqul'>
                                                    {bullet.li.map((item, index) => (
                                                        <ListItem key={index}>
                                                            <ArrowCircleRight className='icon' /> <ListItemText primary={item} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Grid>
                    {/* <Grid item xs={4}>
                        <div>IMAGE</div>
                    </Grid> */}
                </Grid>
            </Container>
            <FooterComp />
        </>
    )
}

export default JasvikMicrosoftComp