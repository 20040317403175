import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Grid } from '@mui/material';
import { JasvikFaq } from '../Components/JasvikContent'
import MenuAppBar from '../Components/Menu';
import FooterComp from '../Components/Footer';
import { ArrowCircleRight } from '@mui/icons-material';

const Faq = () => {
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12} mt={{ xs: 1, sm: 1, md: 13, lg: 13 }}>
                        {JasvikFaq.map((section, index) => (
                            <div key={index} className='mb50 text-left'>
                                <h1 style={{ marginBottom: '0' }}>{section.title}</h1>
                                {section.info.map((infoItem, infoIndex) => (
                                    <div key={infoIndex}>
                                        <h2 className='red-text'>{infoItem.h3}</h2>

                                        {infoItem.h5 && <h4 className=''>{infoItem.h5}</h4>}
                                        <Typography variant="body1">{infoItem.info}</Typography>

                                        {infoItem.h5 && <h4 className=''>{infoItem.h6}</h4>}

                                        {infoItem.bullets && (
                                            <List>
                                                {infoItem.bullets.map((bullet, bulletIndex) => (
                                                    <div key={bulletIndex}>
                                                        <h2 className='red-text'>{bullet.h1}</h2>
                                                        <ListItemText>
                                                            {Array.isArray(bullet.ul) ? (
                                                                <div>
                                                                    {bullet.ul.map((ulItem, ulIndex) => (
                                                                        <div key={ulIndex}>
                                                                            <ListItemText primary={ulItem.ultitle} />
                                                                            <div className='faqul'>
                                                                                    {bullet.ulvalues && bullet.ulvalues.map((ulValue, ulIndex) => (
                                                                                        <div key={ulIndex}>
                                                                                        <ArrowCircleRight className='icon'/> <ListItemText primary={ulValue}/>
                                                                                        </div>
                                                                                    ))}

                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <List className='faqul'>
                                                                    {bullet.li.map((liItem, liIndex) => (
                                                                        <ListItem key={liIndex}>
                                                                            <ArrowCircleRight className='icon'/> <ListItemText primary={liItem} />
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            )}
                                                        </ListItemText>
                                                    </div>
                                                ))}
                                            </List>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Grid>
                    {/* <Grid item xs={4}>
                        <div>IMAGE</div>
                    </Grid> */}
                </Grid>
            </Container>

            <FooterComp />
        </>
    )
}
export default Faq