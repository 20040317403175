import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Grid } from '@mui/material';
import { JasvikAbout } from '../Components/JasvikContent'; 
import MenuAppBar from '../Components/Menu';
import FooterComp from '../Components/Footer';
import { ArrowCircleRight } from '@mui/icons-material';

const About = () => {
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12} mt={{ xs: 1, sm: 1, md: 13, lg: 13 }}>
                        {JasvikAbout.map((section, index) => (
                            <div key={index} className='mb50 text-left'>
                                <h1>{section.title}</h1>
                                {section.info.map((infoItem, infoIndex) => (
                                    <div key={infoIndex}>
                                        {infoItem.h3 && <h2 className='red-text'>{infoItem.h3}</h2>}
                                        
                                        <Typography variant="body1">{typeof infoItem.info === 'string' ? infoItem.info : ''}</Typography>
                                        {typeof infoItem.info === 'object' && (
                                            <List>
                                                {infoItem.info.bullets.map((bullet, bulletIndex) => (
                                                    <div key={bulletIndex}>
                                                        <h2 className='red-text'>{bullet.h1}</h2>
                                                        {bullet.subhead && <Typography variant="subtitle1">{bullet.subhead}</Typography>}
                                                        <List className='faqul'>
                                                            {bullet.li.map((liItem, liIndex) => (
                                                                <ListItem key={liIndex}>
                                                                    <ArrowCircleRight className='icon'/> <ListItemText primary={liItem} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </div>
                                                ))}
                                            </List>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Grid>
                    {/* <Grid item xs={4}>
                        <div>IMAGE</div>
                    </Grid> */}
                </Grid>

            </Container>
            <FooterComp />
        </>
    )
}

export default About