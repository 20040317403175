import { JasvikServicesList } from "../../Components/JasvikContent";
import JasvikServiceLists from "./JasvikServicesLists";
import { useParams } from 'react-router-dom';

function JasvikServicesParent() {
    const { id } = useParams();
    const dataItem = JasvikServicesList.find(service => service.id === parseInt(id));
    return (  
        <JasvikServiceLists service={dataItem} />
    );
  }
  
  export default JasvikServicesParent;