import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Button, ButtonGroup, Card, Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { getJob, filterJob } from './FilterJobs/Jobs'
import { buttons } from './FilterJobs/JobsData'
// import PythonDev from '../Media/images/python-dev.png'

const CareersComp = () => {
  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    width: '25rem',
    height: '15rem',
  };

  const [filtredJob, setFiltredJob] = useState(null);
  const [openPopoverId, setOpenPopoverId] = useState(null);

  useEffect(() => {
    setFiltredJob(getJob());
  }, []);

  function handleJob(e) {
    let typeJob = e.target.value;
    typeJob !== "all"
      ? setFiltredJob(filterJob(typeJob))
      : setFiltredJob(getJob());
  }

  function togglePopover(id) {
    setOpenPopoverId(openPopoverId === id ? null : id);
  }

  return (
    <>
      <section>
        <Container>
          <div style={{}}>
            <div style={{}}>
              <div style={{ marginTop: '50px', marginBottom: '50px' }}>
                <h4 className='red-text'>Careers</h4>
                <h1>Current Open positions</h1>
                <div>
                  <Box>
                    {buttons &&
                      buttons.map((type, index) => (
                        <>
                          <Button sx={{ m: 1, ml: 0, textTransform: 'capitalize', fontWeight: 'light' }} variant="outlined" size='small' style={{ color: 'black' }} key={index} value={type.value} onClick={handleJob}>
                            {type.name}
                          </Button>
                        </>
                      ))}
                  </Box>
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginTop: '5px' }}>
                    {filtredJob &&
                      filtredJob.map(type => (
                        <Grid className='popover__wrapper popover__title' item xs={12} sm={4} md={4} key={type.id} variant="outlined" style={{ border: 'solid 1px #ccc', margin: '15px' }} onClick={() => togglePopover(type.id)}>
                          <CardMedia
                            component="img"
                            alt="green iguana"
                            height="140"
                            src={type.img}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div" align='center'>
                              {type.name}
                            </Typography>
                          </CardContent>
                          {openPopoverId === type.id && (
                            <div class="popover__content">
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="div" align='center'>
                                  {type.name}
                                  <Box sx={{ width: 1, display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap-reverse', marginTop: '15px' }}>
                                    {type.skills && type.skills.map(skill => (
                                      <Chip style={{ margin: '0px 5px 5px 0px' }} key={skill} color="primary" variant="outlined" size='small' label={skill} />
                                    ))}
                                  </Box>
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align='left'>
                                  {type.info}
                                </Typography>
                              </CardContent>
                            </div>
                          )}
                        </Grid>

                      ))}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default CareersComp