import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import JasvikSolutions from '../Media/images/logo-3.png';
import JasvikSolutionsMob from '../Media/images/logo-mob.png';
import HeaderSubMenu from './HeaderSubMenu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ArrowDropDown, ExpandMore } from '@mui/icons-material';
import { JasvikExpertise, JasvikServicesList } from '../Components/JasvikContent';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const theme = createTheme({

})

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  fontSize: 14,
  width: '100%',
  margin: '0px',
  paddingLeft: '0px !important',
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 20,
  },
}));

export default function MenuAppBar({ theme, props }) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isExpertiseOpen, setIsExpertiseOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const handleServicesClick = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const handleExpertiseClick = () => {
    setIsExpertiseOpen(!isExpertiseOpen);
  };

  const handleAboutClick = () => {
    setIsAboutOpen(!isAboutOpen);
  };

  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <ThemeProvider theme={theme}> */}
      {isMobile ? (
        <div className='logo-mob-wraper'>
          <div className='mobmenu-wraper'>
            <div className='logo-mob'>
              <img className='jsvlogo' src={JasvikSolutions} />
            </div>
            <div id="menuToggle">
              <div className='menuinnerwrap'>
                {/* <div className='menuiconwrp'> */}
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                {/* </div> */}
                <ul id="menusub">
                  <Link to='/' underline='none'>Home</Link>
                  <Link className='nav-droplink' underline='none' onClick={handleAboutClick}>About <ExpandMore className={isAboutOpen ? 'rotate-icon' : ''} /></Link>
                  <ul className={`${isAboutOpen ? 'open' : 'dropdown'}`}>
                    <li><Link to="/Pages/About">About Jasvik</Link></li>
                    <li><Link to="/Pages/Faq">FAQs</Link></li>
                  </ul>

                  <Link className='nav-droplink' underline='none' onClick={handleServicesClick}>Services
                    <ExpandMore className={isServicesOpen ? 'rotate-icon' : ''} />
                  </Link>
                  <ul className={`${isServicesOpen ? 'open' : 'dropdown'}`}>
                    {JasvikServicesList && JasvikServicesList.map(service => (
                      <>
                        <li><Link to='/Pages/Services'>Jasvik Services</Link></li>
                        <li key={service.id}><Link to={`/Pages/Services/${service.id}`}>{service.title}</Link></li>
                      </>
                    ))}
                  </ul>
                  <Link className='nav-droplink' onClick={handleExpertiseClick} underline='none'>Expertise
                    <ExpandMore className={isExpertiseOpen ? 'rotate-icon' : ''} />
                  </Link>
                  <ul className={`${isExpertiseOpen ? 'open' : 'dropdown'}`}>
                    {JasvikExpertise && JasvikExpertise.map(page => (
                      <>
                        <li key={page.id}><Link to={`/Pages/Expertise/${page.id}`}>{page.title}</Link></li>
                      </>
                    ))}
                  </ul>

                  <Link to="/Pages/Careers" underline='none'>Careers</Link>
                  <Link to="/Pages/ContactUs/Contact" underline='none'>Contact</Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <HideOnScroll {...props}>
          <AppBar className='mainMenuBar'>
            <>
              <StyledToolbar>
                <HeaderSubMenu />
              </StyledToolbar>
              <Toolbar className='menuRes' style={{ borderTop: 'solid 1px rgb(231 231 231)', backgroundColor: '#505050' }}>
                <div
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  {/* <MenuIcon /> */}
                  <img className='jsvlogo' src={JasvikSolutions} />
                </div>
                <div className='jsvlogomob'>
                  <img src={JasvikSolutionsMob} />
                </div>
                <div className='menubar'>
                  {/* <nav>
                  <Link to="./Pages/Faq">Our Team</Link>
                  <Link to="./Pages/Contact">Contact</Link>
                  <Link to="/">Home</Link>
                  <Link to="/services">services</Link>
                </nav> */}
                  <ul>
                    <Link to='/' underline='none'>Home</Link>
                    <Link to="/Pages/About" className='nav-droplink' underline='none'>About <ArrowDropDown />
                      <ul class="dropdown">
                        {/* After details we can create new page */}
                        {/* <li><Link to="/Pages/Faq">Our Team</Link></li> */}
                        {/* <li><a href="#">Certifications</a></li> */}
                        {/* <li><a href="#">Our Clients</a></li> */}
                        <li><Link to="/Pages/Faq">FAQs</Link></li>
                      </ul>
                    </Link>
                    <Link to='/Pages/Services' className='nav-droplink' underline='none'>Services
                      <ArrowDropDown />
                      <ul class="dropdown">
                        {JasvikServicesList && JasvikServicesList.map(service => (
                          <>
                            <li key={service.id}><Link to={`/Pages/Services/${service.id}`}>{service.title}</Link></li>
                          </>
                        ))}
                      </ul>
                    </Link>
                    <Link className='nav-droplink' underline='none'>Expertise
                      <ArrowDropDown />
                      <ul class="dropdown">
                        {JasvikExpertise && JasvikExpertise.map(page => (
                          <>
                            <li key={page.id}><Link to={`/Pages/Expertise/${page.id}`}>{page.title}</Link></li>
                          </>
                        ))}
                      </ul>
                    </Link>
                    <Link to="/Pages/Careers" underline='none'>Careers</Link>
                    <Link to="/Pages/ContactUs/Contact" underline='none'>Contact</Link>
                  </ul>
                </div>
              </Toolbar>
            </>

          </AppBar>
        </HideOnScroll>
      )}
      {/* </ThemeProvider> */}
    </Box>
  );
}
