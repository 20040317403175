import React from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Button, ThemeProvider, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Slide1 from '../Media/images/tech-bg.jpg'
import Slide2 from '../Media/images/developer-bg.jpg'
import Slide3 from '../Media/images/training.jpg'
import { red } from "@mui/material/colors";
import { Link } from 'react-router-dom';
// import { JasvikExpertise, JasvikServicesList } from '../Components/JasvikContent';

const CarouselContainer = ({theme}) => {
  // const expertiseLink = '/Pages/Services/${service.}'
  return (
    <section>
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        emulateTouch
        autoPlay
        useKeyboardArrows
        transitionTime={1000}
        stopOnHover
        // axis="vertical"
        // selectedItem={1}
        width="100%"
      >
        <div>
          <div  className='slideshow-parent-container'>
            <img
              alt=""
              src={Slide1}
              className="slider-image"
            />
            <div  className="slideshow-child">
              <div className="slideshow-content-container">
                <h1>
                Your Partner in Finding Exceptional Talent
                </h1>
                <p style={{ fontSize: '18px' }}>
                Javik helps you streamline your hiring process and connect you with the right Talent that aligns with your organization's objectives. We meticulously screen and vet candidates to ensure that you only hire the most qualified individuals. Now bridge the gap between talent and industry with Jasvik. 
                </p>
                {/* <p><Button variant="outlined" color="inherit">More <ArrowForward /></Button></p> */}
              </div>

            </div>
          </div>
        </div>
        <div>
          <div className='slideshow-parent-container'>
            <img
              alt=""
              src={Slide2}
              className="slider-image"
            />
            <div className="slideshow-child">
              <div className="slideshow-content-container">
                <h1>
                Hire Contract Talent <br/>in Less than 72 Hours
                </h1>
                <p style={{ fontSize: '18px' }}>
                Strategic Contract Hiring is your gateway to a proficient workforce & successful projects. Jasvik’s Contract Hiring services will help you access specialized skills and expertise for specific projects without the long-term commitment of hiring full-time employees. This flexibility will also allow you to manage costs efficiently.
                </p>
                {/* <p><Link to="/Pages/Services" variant="outlined" color="inherit">More <ArrowForward /></Link></p> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="slideshow-parent-container">
            <img
              alt=""
              src={Slide3}
              className="slider-image"
            />
            <div className="slideshow-child">
              <div className="slideshow-content-container">
                <h1>
                  Technology & creative solution
                </h1>
                <p style={{ fontSize: '18px' }}>
                The best way to set clear and reasonable candidate expectations (which translate to a
successful job posting) is to work with a professional who understands the nuances of
development and the needs of your project.
                </p>
                {/* <p><Button variant="outlined" color="inherit">More <ArrowForward /></Button></p> */}
              </div>

            </div>
          </div>
        </div>
      </Carousel>
    </div>
    </section>
  );
}

export default CarouselContainer