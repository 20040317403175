import React from 'react';
import './App.css';
import Home from './Pages/Home';
import Faq from './Pages/Faq';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Services from './Pages/Services';
import About from './Pages/About';
import JasvikMicrosoftComp from './Pages/Expertise/JasivikMicrosoft';
import { JasvikMicrosoft } from './Components/JasvikContent';
import JasvikExpertiseMain from './Pages/Expertise/MicrosoftMain';
import Expertise from './Pages/Epertise';
import JasvikServicesParent from './Pages/Services/JasvikServicesParent';
import Contact from './Pages/ContactUs/Contact';
import Careers from './Pages/Careers';
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import FooterComp from './Components/Footer';
import ScrollToTop from './Components/ScrollTop';

function App(props) {
  // const theme = createTheme({
  //   palette: {
  //     primary: {
  //       main: "rgba(255,255,255,0.2)"
  //     },
  //     Link: {
  //       main: 'rgb(108 108 108)',
  //     },
  //     typography: {
  //       h1: {
  //         fontSize: '42px !important',
  //         color: 'red',
  //         fontFamily: `"Roboto", sans- serif`,
  //         fontWeight: '300'
  //       },
  //       h2: {
  //         color: '#00FF00',
  //       },
  //       h3: {
  //         color: '#00FF00',
  //       },
  //       p: {
  //         color: '#00FF00',
  //       },
  //     }
  //   }
  // });
  
  return (
    <div className="App">
      {/* <ThemeProvider theme={theme}> */}
        <Router>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Pages/faq" element={<Faq />} />
            <Route path="/Pages/ContactUs/contact" element={<Contact />} />
            <Route path="/Pages/Services" element={<Services />} />
            <Route element={<Expertise />} />
            <Route path="/Pages/About" element={<About />} />
            <Route path="/Pages/Expertise/:id" element={<JasvikExpertiseMain />} />
            <Route path="/Pages/Services/:id" element={<JasvikServicesParent />} />
            <Route path="/Pages/Careers" element={<Careers />} />
          </Routes>
        </Router>
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
