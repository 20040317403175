import React from "react";
import Teams from '../Media/images/teams.webp'
import Exchange from '../Media/images/microsoft-exchane.png'
import Office365 from '../Media/images/microsoft-office-365.jpg'
import Sharepoint from '../Media/images/microsoft-sharepoint.png'
import Azure from '../Media/images/microsoft-azure.png'
import Copilot from '../Media/images/microsoft-copilot.jpg'
import Oracle from '../Media/images/oracle.png'
import Sap from '../Media/images/sap.png'
import AWS from '../Media/images/aws-services.png'
import GoogleCloud from '../Media/images/google-cloud.png'
import DevOps from '../Media/images/devops-business.png'
// import Fullstack from '../Media/images/fullstack.png'

const Banner = () => {
  return (
    <div class="partnerslider">
      <h1>Our Partners</h1>
      <div class="slide-track">
        <div class="slide">
          <img src={Teams} width="100" alt="" />
        </div>
        <div class="slide">
          <img  src={Exchange} width="130" alt="" />
        </div>
        <div class="slide">
          <img  src={Office365} width="180" alt="" />
        </div>
        <div class="slide">
          <img  src={Sharepoint} width="80" alt="" />
        </div>
        <div class="slide">
          <img  src={Azure} width="120" alt="" />
        </div>
        <div class="slide">
          <img  src={Copilot} width="60" alt="" />
        </div>
        <div class="slide">
          <img  src={Oracle} width="90" alt="" />
        </div>
        <div class="slide">
          <img  src={Sap} width="100" alt="" />
        </div>
        <div class="slide">
          <img  src={AWS} width="90" alt="" />
        </div>
        <div class="slide">
          <img  src={GoogleCloud} width="170" alt="" />
        </div>
        <div class="slide">
          <img  src={DevOps} width="120" alt="" />
        </div>
        {/* <div class="slide">
          <img  src={Teams} width="150" alt="" />
        </div> */}
      </div>
    </div>
  );
};

export default Banner
