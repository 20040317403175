import { useParams } from 'react-router-dom';
import JasvikMicrosoftComp from './JasivikMicrosoft';
import { JasvikExpertise } from '../../Components/JasvikContent';

function JasvikExpertiseMain() {
    const { id } = useParams();
    const dataItem = JasvikExpertise.find(item => item.id === parseInt(id));
    return <JasvikMicrosoftComp data={dataItem} />;
}

export default JasvikExpertiseMain;