import * as React from 'react';
import ServicesBg from '../Media/images/about-jasvik.jpg'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Grid, Link } from '@mui/material';
import { ArrowDownwardRounded, ArrowForward, ArrowUpwardRounded, DeveloperMode, ManageAccounts, PersonSearch, Settings } from '@mui/icons-material';
import ServiesImg from '../Media/images/services.png';
import Recruitment from '../Media/images/corporate-team.png';
import ItServices from '../Media/images/it-services.png';
import techImgOne from '../Media/images/tech-bg-1.jpg';
import techImgTwo from '../Media/images/tech-bg-2.jpg'
import techImgThree from '../Media/images/tech-bg-3.jpg'
import techImgFour from '../Media/images/tech-bg-4.jpg'
import { useState } from 'react';


const ServicesComp = () => {
  const commonStyles = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    width: '25rem',
    height: '17rem',
  };

  const [isTextVisible, setIsTextVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleTextVisibility = () => {
    setIsTextVisible(!isTextVisible);
    setIsVisible(!isVisible);
  };

  return (
    <>
      <section>
        <Box className='slideshow-mob color-white' style={{ }}>
          <div style={{  }}>
            <Box sx={{ width: '100%', textAlign: 'left', height: '600px', display: 'block' }} className='service-bg-img'>
              <img style={{ width: '100%', height: '100vh', position: 'absolute',  objectFit: 'cover'}} src={ServicesBg} />
            </Box>
            <div className='services-bg-wraper-container'>
              <Container className=''>
                <Box sx={{ width: '100%', marginTop: '50px', paddingBottom: '30px' }}>
                  <div>
                    <h4 className='color-white'>Services at Jasvik Solutions</h4>
                    <h1 className='color-white'>What we Provide</h1>
                    <Grid container justifyContent={'center'} style={{ paddingBottom: '30px' }}>
                      <Grid item xs={8}>
                        <p className='color-white'>
                          Got a limited budget for a project? Contract Hiring is going to be your
                          best bet. Jasvik’s Contract Hiring services can turn around resumes of viable and skilled
                          candidates that we have partnered with repeatedly in less than 72 hours for all your short-
                          term/project-based needs.
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <figure class="hero-grid effect-move">
                        <img class="hero-grid-image effect-image" src={techImgOne} alt="" />
                        <figcaption class="hero-grid-content">
                          {/* <span class="hero-tag effect-target">Category</span> */}
                          <h2 class="hero-grid-title effect-target"><ManageAccounts className='red-text blue-text xtraLargeFont' /></h2>
                          <h2 class="hero-grid-title effect-target"><strong>Services at Jasvik Solutions</strong></h2>
                          {/* <h2 class="hero-grid-title effect-target" onClick={toggleTextVisibility}>
                            <strong>
                              {isVisible ? <ArrowUpwardRounded /> : <ArrowDownwardRounded />}
                            </strong>
                          </h2> */}
                          <p class="hero-grid-text effect-target effect-text">ERP systems are important to your company because they improve decision-making, streamline processes</p>
                          {/* <a class="hero-grid-link" href="#">View more</a> */}
                        </figcaption>
                      </figure>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <figure class="hero-grid effect-move">
                        <img class="hero-grid-image effect-image" src={techImgTwo} alt="" />
                        <figcaption class="hero-grid-content">
                          {/* <span class="hero-tag effect-target">Category</span> */}
                          <h2 class="hero-grid-title effect-target"><PersonSearch className='red-text blue-text xtraLargeFont' /></h2>
                          <h2 class="hero-grid-title effect-target"><strong>Staffing & Recruitment</strong></h2>
                          <p class="hero-text effect-target effect-text">Jasvik Solutions IT staffing services has over the years placed close to500 employees on client assignments spanning a wide range</p>
                          {/* <a class="hero-grid-link" href="#">View more</a> */}
                        </figcaption>
                      </figure>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <figure class="hero-grid effect-move">
                        <img class="hero-grid-image effect-image" src={techImgThree} alt="" />
                        <figcaption class="hero-grid-content">
                          {/* <span class="hero-tag effect-target">Category</span> */}
                          <h2 class="hero-grid-title effect-target"><DeveloperMode className='red-text blue-text xtraLargeFont' /></h2>
                          <h2 class="hero-grid-title effect-target"><strong>IT Services</strong></h2>
                          <p class="hero-grid-text effect-target effect-text">We are always ready to take up highly demanding software and support projects at short notice which require on the job hands-on and industry experience.</p>
                          {/* <a class="hero-grid-link" href="#">View more</a> */}
                        </figcaption>
                      </figure>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <figure class="hero-grid effect-move">
                        <img class="hero-grid-image effect-image" src={techImgFour} alt="" />
                        <figcaption class="hero-grid-content">
                          {/* <span class="hero-tag effect-target">Category</span> */}
                          <h2 class="hero-grid-title effect-target"><Settings className='red-text blue-text xtraLargeFont' /></h2>
                          <h2 class="hero-grid-title effect-target"><strong>All Services</strong></h2>
                          <p class="hero-grid-text effect-target effect-text">
                            <Link href='/Pages/Services' underline='none' style={{ color: 'rgb(80 80 80)' }}>
                              <div style={{ textAlign: 'left', padding: '15px', height: '-webkit-fill-available', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Link href='/Pages/Services' style={{ display: 'contents' }}>More <ArrowForward style={{ marginTop: '5px' }} fontSize='large' /></Link>
                              </div>
                            </Link>
                          </p>
                          {/* <a class="hero-grid-link" href="#">View more</a> */}
                        </figcaption>
                      </figure>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </div>
          </div>
        </Box>
        </section>
    </>
  );
}

export default ServicesComp