import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Grid } from '@mui/material';
import { JasvikServices } from '../Components/JasvikContent';
import MenuAppBar from '../Components/Menu';
import FooterComp from '../Components/Footer';
import { ArrowCircleRight } from '@mui/icons-material';

const Services = () => {
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12} mt={{ xs: 1, sm: 1, md: 13, lg: 13 }}>
                        {JasvikServices.map((section, index) => (
                            <div key={index} className='text-left mb50'>
                                <h1 style={{ marginBottom: '0' }}>{section.title}</h1>
                                {section.info.map((infoItem, infoIndex) => (
                                    <div key={infoIndex}>
                                        <h2 className='red-text'>{infoItem.h3}</h2>
                                        <Typography variant="body1">{infoItem.info}</Typography>
                                    </div>
                                ))}
                                {section.bullets && (
                                    <div>
                                        <h1>{section.bullets[0].h1}</h1>
                                        <List className='faqul'>
                                            {section.bullets[0].li.map((bullet, bulletIndex) => (
                                                <ListItem key={bulletIndex}>
                                                    <ArrowCircleRight className='icon'/> <ListItemText primary={bullet} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                )}
                            </div>
                        ))}
                    </Grid>
                    {/* <Grid item xs={4}>
                        <div>IMAGE</div>
                    </Grid> */}
                </Grid>

            </Container>
            <FooterComp />
        </>
    )
}

export default Services