import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Grid } from '@mui/material';
import MenuAppBar from '../../Components/Menu';
import { ArrowCircleRight } from '@mui/icons-material';
import FooterComp from '../../Components/Footer';

function JasvikServiceLists({ service }) {
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={2} className='mob-top-innner'>
                    <Grid item md={8} xs={12} mt={{ xs: 1, sm: 1, md: 13, lg: 13 }}>
                        <div key={service.id} className='mb50 text-left'>
                            <h1>{service.title}</h1>
                            {service.h3 && <h3>{service.h3}</h3>}
                            {service.info && (
                                <div>
                                    {service.info.map((infoItem, infoIndex) => (
                                        <div key={infoIndex} style={{marginBottom: '15px'}}>
                                            <Typography variant="body1">{infoItem.p1}</Typography>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {service.bullets && (
                                <div>
                                    {service.bullets.map((bullet, bulletIndex) => (
                                        <div key={bulletIndex}>
                                            <h2 className='red-text'>{bullet.h1}</h2>
                                            <List className='faqul'>
                                                {bullet.li.map((item, index) => (
                                                    <ListItem key={index}>
                                                        <ArrowCircleRight className='icon' /> <ListItemText primary={item} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {service.bullets2 && (
                                <div>
                                    {service.bullets2.map((bullet, bulletIndex) => (
                                        <div key={bulletIndex}>
                                            <h2 className='red-text'>{bullet.h1}</h2>
                                            <List className='faqul'>
                                                {bullet.li.map((item, index) => (
                                                    <ListItem key={index}>
                                                        <ArrowCircleRight className='icon' /> <ListItemText primary={item} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Grid>
                    {/* <Grid item xs={4}>
                        <div>IMAGE</div>
                    </Grid> */}
                </Grid>
            </Container>
            <FooterComp />
        </>
    );
}

export default JasvikServiceLists