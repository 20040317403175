import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Grid } from '@mui/material';
import { JasvikServices } from '../Components/JasvikContent';
import MenuAppBar from '../Components/Menu';
import FooterComp from '../Components/Footer';
import { ArrowCircleRight } from '@mui/icons-material';
import CareersComp from '../Components/CareersComp';

const Careers = () => {
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs={12} mt={{ xs: 0, sm: 0, md: 10, lg: 10 }}>
                        <CareersComp/>
                    </Grid>
                </Grid>

            </Container>
            <FooterComp />
        </>
    )
}

export default Careers