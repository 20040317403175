export const JasvikFaq = [
    {
        id: 1,
        title: "FAQ's",
        // bullets: ['Continuous Integration', 'Continuous Delivery', 'Microservices', 'Infrastructure as Code'],
        info: [
            {
                id: 1,
                h3: 'What makes Jasvik different from other Talent firms?',
                info: `Jasvik Solutions offers you tailored Talent Solutions with a 98% Employee Retention Rate
                and 20+ Years of cumulative Hiring experience, making it cost-effective to ensure you do
                not incur any bad hire costs.`
            },
            {
                id: 2,
                h3: 'What does Jasvik specialize in?',
                h5: 'Jasvik specializes in the following areas -',
                info: `Jasvik has Talent Experts on the team to help our clients find the right fit for their ERP,
                Data Science, DevOps & Full Stack Development.`,
                h6: `Jasvik provides top talent for the following:`,
                bullets: [
                    {
                        h1: `ERP`,
                        subhead: 'Microsoft',
                        li: ['Microsoft Teams', 'Microsoft Exchange', 'Microsoft Office 365', 'Microsoft SharePoint', 'Microsoft Copilot', 'Microsoft Azure', 'Oracle', 'SAP', 'AWS', 'Google Cloud Platform',]
                    },
                    {
                        h1: `Data Science`,
                        subhead: '',
                        li: ['Data Engineer', 'Big Data', 'Python', 'Artificial Intelligence']
                    },
                    {
                        h1: `DevOps`,
                        subhead: '',
                        li: ['Full Stack Java Development']
                    },
                ]
            },
            {
                id: 3,
                h3: 'What are Jasvik’s Top Service offerings?',
                h5: 'Jasvik has unmatched expertise in:',
                info: ``,
                bullets: [
                    {
                        h1: ``,
                        subhead: '',
                        li: ['Contract Hire', 'Permanent Hire', 'Managed Services', 'Offshore Hire']
                    },
                ]
            },
            {
                id: 4,
                h3: 'What is Jasvik’s employee retention rate?',
                info: `Jasvik’s Talent Solutions ensures a striking 98% employee retention rate, keeping you
                from incurring any bad hire costs.`
            },
            {
                id: 5,
                h3: 'How much industry experience do you have?',
                info: `Our team has a cumulative experience in the Talent industry of 20+ years.`
            },
            {
                id: 6,
                h3: 'Who are your clients?',
                info: `coming soon`
            },
            {
                id: 7,
                h3: 'How many open positions are there currently at Jasvik?',
                info: `To know all the insights on our open positions and to apply-`
            },
        ],
        // img: DevOps
    },
]

export const JasvikServices = [
    {
        id: 1,
        title: 'Our Services',
        info: [
            {
                id: 1,
                h3: 'Contract Hiring',
                info: `Got a limited budget for a project? Contract Hiring is going to be your
                best bet. Jasvik’s Contract Hiring services can turn around resumes of viable and skilled
                candidates that we have partnered with repeatedly in less than 72 hours for all your short-
                term/project-based needs.`
            },
            {
                id: 2,
                h3: 'Permanent Hiring',
                info: `Hire the RIGHT Full-Time Talent in less than 8 weeks with Jasvik.
                Hiring just ANYONE with lesser capabilities than you are looking for will cost you your
                time, money, and effort. With the already challenging business environment today, you
                should leave that off your list of things to look after.`
            },
            {
                id: 3,
                h3: 'Managed Services',
                info: `Jasvik’s Managed Services encompasses a comprehensive suite of
                Talent solutions designed to alleviate the burdens associated with workforce
                management. From talent acquisition and onboarding to payroll administration and
                compliance, Jasvik will be your strategic partner, seamlessly integrating with clients
                operations to streamline processes and optimize resource utilization.`
            },
            {
                id: 4,
                h3: 'Offshore Hiring',
                info: `Jasvik’s Offshore Hiring Service provides you access to a diverse pool of
                highly skilled professionals worldwide, enabling companies to tap into specialized
                expertise and capabilities that may not be readily available domestically. Hiring Offshore
                Talent facilitates around-the-clock operations and faster project turnaround times,
                thereby enhancing productivity and accelerating business growth.`
            },
        ],
        bullets: [
            {
                h1: `Our Industries`,
                subhead: '',
                li: ['Public Sector', 'Healthcare', 'Education', 'Commercial Organizations']
            },
        ]
        // img: 
    },
]

export const JasvikAbout = [
    {
        id: 1,
        title: 'About Jasvik Solutions',
        info: [
            {
                id: 1,
                h3: '',
                info: `As Doug Conant rightly said, “To win the marketplace, you must first win the workplace.”`
            },
            {
                id: 2,
                h3: '',
                info: `In today's competitive business landscape, finding the right Talent for your open
                position(s) can be a strategic advantage.`
            },
            {
                id: 3,
                h3: 'Managed Services',
                info: `Jasvik’s Talent Solutions ensures a striking 98% employee retention rate, keeping you
                from incurring any bad hire costs. With a cumulative experience of 20+ years on board,
                Jasvik’s team holds a specialization in:`
            },
            {
                id: 4,
                h3: '',
                info: {
                    id: 1,
                    bullets: [
                        {
                            h1: `ERP`,
                            // subhead: 'Microsoft',
                            li: ['Microsoft Teams', 'Microsoft Exchange', 'Microsoft Office 365', 'Microsoft SharePoint', 'Microsoft Copilot', 'Microsoft Azure', 'Oracle', 'SAP', 'AWS', 'Google Cloud Platform',]
                        },
                        {
                            h1: `Data Science`,
                            subhead: '',
                            li: ['Data Engineer', 'Big Data', 'Python', 'Artificial Intelligence']
                        },
                        {
                            h1: `DevOps`,
                            subhead: '',
                            li: ['Full Stack Java Development']
                        },
                    ]
                },
            },
        ],
        // img: 
    },
]

export const JasvikExpertise = [
    {
        id: 1,
        title: 'Microsoft Page',
        h3: '',
        info: [
            {
                p1: `With a relentless commitment to excellence, Jasvik’s Microsoft Talent Experts are
                dedicated to identifying and attracting skilled professionals who share a passion for
                pushing boundaries and shaping the future.`,
            },
            {
                p1: `Through a combination of cutting-edge recruitment strategies, inclusive practices, and a
                strong emphasis on fostering a culture of growth, Jasvik Solutions sets the stage for
                individuals to thrive and contribute to your organization's ongoing legacy of
                transformative impact.`,
            },
        ],
        
        bullets: [
            {
                h1: `Microsoft Talent Services`,
                li: ['Microsoft Teams', 'Microsoft Exchange', 'Microsoft Office 365', 'Microsoft SharePoint', 'Microsoft Copilot', 'Microsoft Azure']
            },
        ]
        // img: 
    },
    {
        id: 2,
        title: 'Oracle',
        h3: 'Maximize your Oracle investment with Jasvik',
        info: [
            {
                p1: `Jasvik’s Oracle Talent Expert team specializes in helping organizations find their most
                suitable talent to optimize their use of Workday for business growth. For our
                Permanent/Full-Time Exclusive services, we build a talent pipeline from scratch to match
                our client's expectations of the candidate. We do this after understanding the
                requirements better through our Intake Calls and Steering Meetings.`,
            },
            {
                p1: `We provide Contract and Offshore Hiring services to better assist you by finding the right
                fit for your Oracle projects.`,
            },
        ],
        
        bullets: [
            {
                h1: `Oracle Talent Services`,
                li: ['Oracle HCM', 'Oracle EPM', 'Oracle Data Cloud', 'Oracle ERP Financial Management', 'Oracle Communications Security Shield', 'Oracle SCM & Manufacturing', 'Oracle Customer Experience', 'Oracle Analytics', 'Other Applications']
            },
        ]
        // img: 
    },
    {
        id: 3,
        title: 'SAP',
        h3: 'Maximize your SAP investment with Jasvik',
        info: [
            {
                p1: `Jasvik is here to extend our time-tested services to help you witness what hiring the right
                SAP Talent can do for your business.`,
            },
            {
                p1: `We have a robust talent acquisition process that we often hear our clients rave about,
                which only motivates us to do better. Our Talent Services has helped us increase our
                employee retention rate to 98%, ensuring you do not incur any bad hire costs.`,
            },
        ],
        
        bullets: [
            {
                h1: `SAP Talent Services`,
                li: ['Financial Accounting and Controlling (FICO)', 'Human Capital Management (HCM)', 'Material Management (MM)', 'Plant Maintenance (PM)', 'Production Planning (PP)', 'Quality Management (QM)', 'Sales Distribution (SD)']
            },
        ]
        // img: 
    },
    {
        id: 4,
        title: 'AWS',
        h3: 'Maximize your AWS investment with Jasvik',
        info: [
            {
                p1: `AWS is architected to be the most flexible and secure cloud computing environment
                available today. Our core infrastructure is built to satisfy the security requirements of the
                military, global banks, and other high-sensitivity organizations.`,
            },
            {
                p1: `Jasvik Solutions helps you find AWSTalent that would go beyond just their resume and
                truly align with your company’s mission & vision.`,
            },
            {
                p1: `We do not believe in tackling the surface problem alone, of finding a mere candidate who
                would be a technical fit, but we go in-depth in understanding and analyzing if they would
                be a cultural fit as well. That is what helps us find long-term employees for our clients.`
            },
        ],
        
        bullets: [
            {
                h1: `AWS Talent Services`,
                li: ['Analytics', 'Application Integration', 'Developer Tools', 'Internet of Things', 'Machine Learning', 'Management & Governance', 'Security, Identity & Compliance', 'Networking & Content Delivery']
            },
        ]
        // img: 
    },
    {
        id: 5,
        title: 'Google Cloud',
        h3: 'Maximize your Google Cloud Platform investment with Jasvik',
        info: [
            {
                p1: `The Google Cloud Platform Talent Expert team at Jasvik Solutions specializes in
                connecting organizations with the top talent for your Google Cloud projects. We believe
                in not just providing talent but building long-term relationships with any client we interact
                with. Building such relationships requires trust, integrity, and results.`,
            },
            {
                p1: `Jasvik has been fortunate to have worked with some renowned organizations for their
                Google Cloud projects and continues to nurture the associations.`,
            },
        ],
        
        bullets: [
            {
                h1: `Google Cloud Talent Services`,
                li: ['Compute Engine', 'Cloud Storage', 'Big Query', 'Cloud Run', 'Vertex AI Platform', 'Looker', 'Cloud SQL', 'Apigee API Management']
            },
        ]
        // img: 
    },
    {
        id: 6,
        title: 'Data Engineering Excellence',
        // h3: 'Data Science Talent',
        info: [
            {
                p1: `Unlock the full potential of your business with our top-notch Data Engineering services. With
                Jasvik's expert team boasting over 20 years of cumulative experience, finding the perfect Data
                Engineering talent has never been easier or more efficient.`,
            },
            {
                p1: `Our dedicated team specializes in sourcing and securing the best Data Engineering
                professionals, ensuring a 98% guaranteed employee retention rate. Whether you require
                expertise in specific technologies like Python, Big Data, or Artificial Intelligence (AI), or industry-
                specific knowledge, our screening process is tailored to your needs, delivering only the most
                qualified candidates.`,
            }
        ],
        
        bullets: [
            {
                h2: `Our Data Engineering Services Include`,
                h1: `Data Engineering Talent Acquisition`,
                li: [`Data Engineering Talent Acquisition Find the perfect fit for your organization from our pool of highly skilled Data Engineers.`]
            },
            {
                h1: `Python`,
                li: ['Leverage the power of Python for efficient data processing and analysis.']
            },
            {
                h1: `Big Data Solutions`,
                li: ['Harness the potential of Big Data technologies to handle large volumes of data and extract valuable insights.']
            },
            {
                h1: `Artificial Intelligence (AI)`,
                li: ['Drive innovation and automation with AI solutions tailored to your business needs.']
            },
            {
                h2: `Additional Expertise`,
                h1: `Data Analytics & Business Intelligence Staffing`,
                li: ['Utilize leading tools like Power BI, Tableau, and Alteryx for comprehensive data analysis.']
            },
            {
                h1: `Data Science & Machine Learning Staffing`,
                li: ['Access top talent proficient in AWS, Azure, Kafka, Spark, Hadoop, and more.']
            },
            {
                h1: `Data Engineering & Big Data Staffing`,
                li: ['Build robust data pipelines with expertise in Python, SAS, Pandas, NumPy, and other essential technologies.']
            },
            {
                h1: ``,
                li: [`This revised section highlights the comprehensive Data Engineering services offered by Jasvik,
                emphasizing expertise, efficiency, and tailored solutions to meet the unique needs of each
                client.`]
            },
        ]
        // img: 
    },
    {
        id: 7,
        title: 'DevOps',
        h3: '',
        info: [
            {
                p1: `The DevOps model relies on effective tooling to help your teams rapidly and reliably
                deploy and innovate.`,
            },
            {
                p1: `With a relentless commitment to excellence, Jasvik Solutions’s DevOps Talent Acquisition
                team is dedicated to identifying and attracting skilled professionals who share a passion
                for pushing boundaries and shaping the future.`,
            },
        ],
        
        bullets: [
            {
                h1: `DevOps Talent Services`,
                li: ['Continuous Integration', 'Continuous Delivery', 'Microservices', 'Infrastructure as Code', 'Monitoring and Logging', 'Communication and Collaboration']
            },
        ]
        // img: 
    },
    {
        id: 8,
        title: 'Full Stack Java Development',
        h3: '',
        info: [
            {
                p1: `Full Stack Java Developers often know many programming languages. However, the best
                candidate is a Developer who possesses the required skill set that you need for your
                specific project.`,
            },
            {
                p1: `The best way to set clear and reasonable candidate expectations (which translate to a
                    successful job posting) is to work with a professional who understands the nuances of
                    development and the needs of your project.`,
            },
            {
                p1: `We at Jasvik Solutions work closely with our clients to create clear job postings that
                source the right candidates with pinpoint accuracy.`,
            },
        ],
        
        bullets: [
            {
                h1: `Jasvik Offers Top Talent Services for a variety of Full Stack Developers`,
                li: ['Full Stack - Software Development Engineer', 'Full Stack Web Developer', 'Full Stack Engineer']
            },
            {
                h1: `Full Stack Java Development Talent Services`,
                li: ['JavaScript', 'HTML / CSS', 'Git & GitHub', 'Databases & Web Storage', 'Web Architecture', 'Unit Testing skills', 'APIs & Libraries', 'JVM Internals', 'IDE (such as Eclipse or IntelliJIDEA)', 'Front-end framework (such as Angular or React)']
            },
        ]
        // img: 
    },
]

export const JasvikServicesList = [
    {
        id: 1,
        title: 'Contract Hiring',
        h3: 'Jasvik’s Contract Hiring Services offers a spectrum of benefits, some of which are',
        info: [
            {
                p1: `You can get the job done efficiently by top professionals without having to commit full-
                time.`,
            },
            {
                p1: `Jasvik’s Contract Hiring services allow you to quickly scale your workforce up or down
                based on project demands, ensuring you have the right skills at the right time.`,
            },
            {
                p1: `Get your Contract Hire in less than 72 hours with Jasvik.`,
            },
        ],
        
        bullets: [
            {
                h1: `Specialized Skills`,
                li: [`You can access specialized skills and expertise for specific projects without the long-term commitment of hiring full-time employees.`]
            },
            {
                h1: `Cost Savings`,
                li: [`Being paid hourly or on a project basis eliminates the costs
                associated with full-time employees, such as benefits, paid time off, and training
                expenses.`]
            },
            {
                h1: `Reduced Risk`,
                li: [`Reduce the risk associated with termination.`]
            },
            {
                h1: `Adaptability`,
                li: [`You can scale your workforce up or down according to project
                demands or business cycles.`]
            },
            {
                h1: `Quick Hiring Process`,
                li: [`Faster hiring process compared to permanent positions,
                allowing you to quickly fill gaps in your workforce.`]
            },
            {
                h1: `Trial Period`,
                li: [`Serves as a trial period for both parties. If the contractor performs
                well, there may be opportunities for long-term employment. Conversely, if the fit
                isn&#39;t right, parting ways is easier.`]
            },
        ],
        bullets2: [
            {
                h1: `Top Contract Hires within 72 Hours`,
                li: [`Speed To Market - We secure Top Contract Talent within 72 hours.`,
                `Right Fit - Using our Talent Acquisition process, we filter out candidates to only
                present the best to you.`,
                 `Strong Talent Pipeline - We have a talent pipeline of more than 10,000, which helps
                us find you the right Talent as soon as possible.`,
                `Metrics - We track each requirement from start to close. Our metric measurement
                process ensures that the candidates we find are the best fit.`
            ]
            },
        ]
        // img: 
    },
    {
        id: 2,
        title: 'Permanent Hiring',
        h3: '',
        info: [
            {
                p1: `If your focus is on building a high-performing team(s), Jasvik’s Permanent/Full-Time
                services are crafted to meet your specific Talent needs.`,
            },
            {
                p1: `Jasvik Permannet/Full-time Hiring Services helps you get the right Talent for your hiring
                requirements in less than 6 weeks. Along with this, you get a whopping 98% employee
                retention rate, ensuring you stay away from any bad hire costs.`,
            },
            
        ],
        
        bullets: [
            {
                h1: `Specialized Skills`,
                li: [`98% Employee Retention Rate`, `Hire in 6 weeks`, `No Bad Hire Costs`, `100% Client Satisfaction`]
            },
            {
                h1: `DEI at Jasvik`,
                p1: `At Jasvik, we understand that diversity, equity, and inclusion are critical components for
                achieving success in the workplace. We proactively ensure that our talent pipelines are
                filled with candidates from different backgrounds and eliminate unintentional biases from
                our screening processes.`,
                li: []
            },
            {
                h1: `Here’s how we strive to make our Talent Acquisition process DEI-centric:`,
                li: [`Proactively seed talent pipelines with diverse candidates.`,
            `Rethink screening factors to remove unintentional biases.`,
        `Work with clients to abide by their policies that appeal to diverse candidates.,`,
    `Audit job descriptions to ensure that DEI policies are visible and aligned.`]
            },
        ],
        // img: 
    },
    {
        id: 3,
        title: 'Managed Services',
        h3: `Here’s why choosing Jasvik as your Managed Services Provider will be the best decision
        for your organization:`,
        info: [
            {
                p1: `Tailor-fit services to manage your Contingent workforce programs.`,
            },
            {
                p1: `Jasvik’s Managed Services are designed from discovery to implementation to drive results
                and help you make informed and strategic decisions.`,
            },
            
        ],
        
        bullets: [
            {
                h1: `We work with a Deep Understanding &amp; in Alignment with Your Goals.`,
                li: [`We dive into all facets of your organization, learning its processes and procedures
                so we can recommend the best possible approach to meet your organizational
                goals.`]
            },
            {
                h1: `Dynamic Reporting & Actionable`,
                li: [`Actionable, real-time, customized analytics delivered across your user base to
                drive your strategic goals from – initiative identification to achievement tracking.`]
            },
            {
                h1: `Create & Foster a Culture of Excellence`,
                li: [`Our approach creates a culture of excellence, highlighting key suppliers while
                fostering your organization's next generation of high performers.`]
            },
        ],
        // img: 
    },
    {
        id: 4,
        title: 'Offshore Hiring',
        h3: ``,
        info: [
            {
                p1: `Jasvik’s Offshore Hiring service enables access to a diverse talent pool with specialized
                skills that may not be available locally.`,
            },
            {
                p1: `Hiring Offshore Talent is going to help you in multiple ways, some of which are:`,
            },
        ],
        
        bullets: [
            {
                
                li: [`Reduce Overhead Costs`, `Increased Efficiency &amp; Productivity`, `Risk Mitigation`, `Flexibility in Resource Allocation`, `Cultural Diversity`]
            },
        ],
        // img: 
    },
]