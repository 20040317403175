import * as React from 'react';
import { Box, Link } from '@mui/material';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import { Email, PhoneAndroid, Twitter } from '@mui/icons-material';
import { ThemeProvider } from "@mui/material/styles";


const HeaderSubMenu = () => {
    
    return (
        // <ThemeProvider theme={theme}>
            <Box className='subMenuStyle' sx={{bgcolor: 'background.paper'}}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Link underline="none" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', fontSize: '14px', color: '#505050' }}
                        onClick={() => window.location = 'mailto:yourmail@domain.com'}>hr@jasviksolutions.com
                        <Email style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} />
                    </Link>
                    <Link underline="none" sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginLeft: '15px', fontSize: '14px', color: '#505050' }} href="tel: (571) 564 - 3239" >(571) 564 - 3239
                        <PhoneAndroid style={{ fontSize: '16px', marginLeft: '6px', marginRight: '6px' }} />
                    </Link>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Link style={{ fontSize: '16px', margin: '0 5px', color: '#505050' }} href="#" ><Facebook fontSize='small' /></Link>
                    <Link style={{ fontSize: '16px', margin: '0 5px', color: '#505050' }} href="#"><Twitter fontSize='small' /></Link>
                    <Link style={{ fontSize: '16px', margin: '0 5px', color: '#505050' }} href="#" ><Instagram fontSize='small' /></Link>
                </Box>
            </Box>
        // </ThemeProvider>
    );
}

export default HeaderSubMenu