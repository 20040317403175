import { Facebook, Google, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { Container } from '@mui/material';
import React, { Component } from 'react';
import JasvikSolutions from '../Media/images/logo-3.png';
import { Link } from 'react-router-dom';

const FooterComp = () => {
    return (
        <footer>
            <div className="top">
                {/* <Container> */}
                    <div className="pages">
                        <ul>
                            <h3 style={{color: 'white'}}>Jasvik Solutions</h3>
                            <li><Link to='/' underline='none'>Home</Link></li>
                            <li><Link to="/Pages/About">About Jasvik</Link></li>
                            <li><Link to='/Pages/Services'>Services</Link></li>
                            {/* <li><Link to="#">Certifications</Link></li>
                            <li><Link href="#">Our Clients</Link></li>
                            <li><Link href="#">FAQs</Link></li> */}
                            
                        </ul>

                        <ul>
                            <h3 style={{color: 'white'}}>Careers</h3>
                            <li><Link to="/Pages/Careers">Apply Online</Link></li>
                            <li><Link to="#">Available Positions</Link></li>
                        </ul>

                        <ul>
                            <h3 style={{color: 'white'}}>About Us</h3>
                            <li><Link to="/Pages/About">Our Team</Link></li>
                            <li><Link to="/Pages/Expertise/1">Expertise</Link></li>
                            <li><Link to="/Pages/Faq">FAQs</Link></li>
                            <li><Link to="/Pages/ContactUs/Contact">Contact Us</Link></li>
                        </ul>
                    </div>

                    <div style={{color: 'white'}} className="newsletter text-left">
                        <img src={JasvikSolutions} />
                        <p style={{color: 'white'}}>13873, Park Centre Road, <br/>
                        Suite #185 Herndon,<br/> Virginia 20171</p>
                            <br/>
                        <h3 style={{color: 'white'}}>Follow us on</h3>
                        <Link color={'inherit'} to='#'><Facebook /></Link>
                        <Link color={'inherit'} to='#'><Instagram /></Link>
                        <Link color={'inherit'} to='#'><Twitter /></Link>
                        <Link color={'inherit'} to='#'><YouTube /></Link>
                        <Link color={'inherit'} to='#'><LinkedIn /></Link>
                        <Link color={'inherit'} to='#'><Google /></Link>
                    </div>
                {/* </Container> */}
            </div>
            <div className="info">
                {/* <Container> */}
                    <div class="legal">
                        <a href="#">Terms & Conditions</a><a href="#">Privacy Policy</a>
                    </div>
                    <div class="copyright">2024 Copyright &copy; <b>Jasvik Solutions</b></div>
                {/* </Container> */}
            </div>
        </footer>
    )
}

export default FooterComp