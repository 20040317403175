import { useEffect } from "react"
import $ from "jquery";
import { Apartment, Diversity2, Groups, School } from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";

const CustomerCounters = () => {

    useEffect(() => {
        $('.counting').each(function () {
            var $this = $(this),
                countTo = $this.attr('data-count');

            $({ countNum: $this.text() }).animate({
                countNum: countTo
            },
                {
                    duration: 3000,
                    easing: 'linear',
                    step: function () {
                        $this.text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $this.text(this.countNum);
                        //alert('finished');
                    }
                });
        });
    }, [])
    return (
        <>
            <section id="counter-stats" className="">
                <Container>
                    <Box sx={{ width: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={3} lg={3} className='stats'>
                                <Apartment className="icons" />
                                <h5>Our Offices</h5>
                                <div className="counting" data-count="10">0</div>
                                <p>We always provide people a
                                    complete solution upon focused of
                                    any business</p>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3} className='stats'>
                                <School className="icons" />
                                <h5>Completed Cases</h5>
                                <div className="counting" data-count="489">0</div>
                                <p>We always provide people a
                                    complete solution upon focused of
                                    any business</p>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3} className='stats'>
                                <Groups className="icons" />
                                <h5>Skilled People</h5>
                                <div className="counting" data-count="300">0</div>
                                <p>We always provide people a
                                    complete solution upon focused of
                                    any business</p>
                            </Grid>

                            <Grid xs={12} sm={12} md={3} lg={3} className='stats'>
                                <Diversity2 className="icons" />
                                <h5>Happy clients</h5>
                                <div className="counting" data-count="100">0</div>
                                <p>We always provide people a
                                    complete solution upon focused of
                                    any business</p>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </section>
        </>
    )
}

export default CustomerCounters