import DevOps from '../../Media/images/python-dev.png'
import Fullstack from '../../Media/images/fullstack.png'
import Sap from '../../Media/images/sap.png'
import Cloud from '../../Media/images/cloud.png'
import DataScience from '../../Media/images/data-science.png'
import Oracle from '../../Media/images/oracle.png'
export const Jobs = [
  {
    id: 1,
    name: "DevOps",
    type: "all",
    skills: ['Continuous Integration', 'Continuous Delivery', 'Microservices', 'Infrastructure as Code'],
    info: 'Jasvik Solutions’s DevOps Talent Acquisition team is dedicated to identifying and attracting skilled professionals.',
    img: DevOps
  },
  {
    id: 2,
    name: "Data Science",
    type: "cloud",
    skills: ['Data Engineer', 'Big Data', 'Artificial Intelligence (AI)'],
    info: 'Using the expertise of Jasvik’s team of 20+ cumulative years, finding the best Data Science talent for your organization is now 10x easier.',
    img: DataScience
    // info2: 'Produce visual and interactive elements and taking an active role on both sides and defining how the application looks as well as how it works.'
  },
  {
    id: 3,
    name: "AWS",
    type: "cloud",
    skills: ['Analytics', 'Internet of Things', 'Networking & Content Delivery'],
    info: 'Jasvik Solutions helps you find AWSTalent that would go beyond just their resume and truly align with your company’s mission &  vision.',
    img: Cloud
  },
  {
    id: 4,
    name: "Fullstack Developer",
    type: "fullstack developer",
    skills: ['JavaScript', 'HTML / CSS', 'Databases & Web Storage'],
    info: 'Full Stack Java Developers often know many programming languages. However, the best candidate is a Developer who possesses the required skill set that you need for your specific project.',
    img: Fullstack
  },
  {
    id: 5,
    name: "SAP",
    type: 'sap',
    skills: ['Plant Maintenance', 'Quality Management', 'Material Management'],
    info: 'We have a robust talent acquisition process that we often hear our clients rave about, which only motivates us to do better.',
    img: Sap
  },
  {
    id: 6,
    name: "Oracle",
    type: 'oracle',
    skills: ['Oracle HCM', 'Oracle Data Cloud', 'Oracle EPM'],
    info: 'Jasvik’s Oracle Talent Expert team specializes in helping organizations find their most suitable talent to optimize their use of Workday for business growth.',
    img: Oracle
  },
  // {
  //   id: 7,
  //   name: 'Microsoft Talent',
  //   type: 'microsoft talent',
  //   info: 'With a relentless commitment to excellence, Jasvik’s Microsoft Talent Experts are dedicated to identifying and attracting skilled professionals'
  // }
];

export const buttons = [
  {
    name: "All",
    value: "all"
  },
  {
    name: "Cloud Management",
    value: "cloud"
  },
  {
    name: "Oracle",
    value: "oracle"
  },
  {
    name: "SAP",
    value: "sap"
  },
  {
    name: "Fullstack Developer",
    value: "fullstack developer"
  }
];
