import React, { useState } from 'react';
import MenuAppBar from '../../Components/Menu';
import FooterComp from '../../Components/Footer';
import axios from 'axios';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';

const Contact = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        title: '',
        phone: '',
        details: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/sendEmail', formData);
            console.log('Email sent successfully');
            // Add logic to handle successful email sending (e.g., show success message)
        } catch (error) {
            console.error('Error sending email:', error);
            // Add logic to handle email sending error (e.g., show error message)
        }
    };
    return (
        <>
            <MenuAppBar />
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={8} mt={{ xs: 1, sm: 1, md: 13, lg: 13 }}>
                        <div className='mb50 text-left'>
                            <h1>Contact us</h1>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Details"
                                    name="details"
                                    multiline
                                    rows={4}
                                    value={formData.details}
                                    onChange={handleChange}
                                />
                                <Button type="submit" variant="contained" color="primary">Submit</Button>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div>IMAGE</div>
                    </Grid>
                </Grid>

            </Container>
            <FooterComp />
        </>
    )
}

export default Contact