import React from "react";
import VideoBanner from "./BannerVideo";
import MenuAppBar from "./Menu";
import CarouselContainer from "./Carousel";

const VideoWraper = ({theme}) => {
    return (
        <>
            <div className="" style={{display: 'block', maxWidth: '100%'}}>
            <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
                <div style={{ position: "absolute", width: '100%', top: '0', right: '0', bottom: '0', left: '0' }}>
                    <MenuAppBar />
                    <VideoBanner />
                    {/* <CarouselContainer/> */}
                </div>
            </div>
            </div>
        </>
    )
}

export default VideoWraper