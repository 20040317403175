import { Jobs } from "./JobsData";

export function getJob() {
  const JobList = Jobs;
  return JobList;
}

export function filterJob(jobType) {
  let filtredJob = getJob().filter(element => element.type === jobType && element.name.trim() !== '');
  return filtredJob;
}