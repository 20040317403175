import * as React from 'react';
import AboutJasvik from '../Media/images/about-jasvik.jpg'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Button, ButtonGroup, Chip, Grid, Typography } from '@mui/material';
import { Block, Check, CheckCircle, Done } from '@mui/icons-material';

const AboutComp = () => {
  return (
    <>
      <Container>
        <div style={{ display: 'block', width: '100%', }}>
          <Grid className='about-wraper' container sx={{ width: 1 }} spacing={2}>
            <Grid item xs={12} md={8} lg={6} align='left'>
              <h4 className='red-text'>About Jasvik Solutions</h4>
              <h1 style={{ marginBottom: '0' }}>STAFFING & RECRUITMENT SERVICES</h1>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'start' }}>
                    <p>
                      Jasvik Solutions is a Staffing and Recruitment Services company
                      that specializes in providing staffing and recruitment solutions to
                      businesses and organizations.
                    </p>
                  </Box>
                  <Grid container sx={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap-reverse' }} spacing={1}>
                    <Grid item>
                      <Chip
                        label="Determine the staffing needs"
                        color="success"
                        variant="outlined"
                        icon={<Check />}
                      />
                      {/* <Button sx={{ textTransform: 'capitalize', fontWeight: 'light' }} variant="outlined">Determine the staffing needs</Button> */}
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Job Description and candidate"
                        color="success"
                        variant="outlined"
                        icon={<Check />}
                      />
                      {/* <Button sx={{ textTransform: 'capitalize', fontWeight: 'light' }} variant="outlined">Job Description and candidate</Button> */}
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Screening and Interviewing needs"
                        color="success"
                        variant="outlined"
                        icon={<Check />}
                      />
                      {/* <Button sx={{ textTransform: 'capitalize', fontWeight: 'light' }} variant="outlined">Screening and Interviewing needs</Button> */}
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Sourcing Candidate"
                        color="success"
                        variant="outlined"
                        icon={<Check />}
                      />
                      {/* <Button sx={{ textTransform: 'capitalize', fontWeight: 'light' }} variant="outlined">Sourcing Candidate</Button> */}
                    </Grid>
                    <Grid item>
                      <Chip
                        label="Hiring and onboarding"
                        color="success"
                        variant="outlined"
                        icon={<Check />}
                      />
                      {/* <Button sx={{ textTransform: 'capitalize', fontWeight: 'light' }} variant="outlined">Hiring and onboarding</Button> */}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'end', alignItems: 'center' }}>
              <img src={AboutJasvik} alt="About Jasvik" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default AboutComp