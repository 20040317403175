import { Box, Button, Container, ThemeProvider } from "@mui/material";
// import video from '../Media/videos/background-video.mp4'
import React, { Suspense, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
// import CarouselContainer from "./Carousel";
const CarouselContainer = React.lazy(() => import('./Carousel'));

const VideoBanner = ({theme}) => {
    const [userInteracted, setUserInteracted] = useState(true);

    const handlePlay = () => {
        if (!userInteracted) {
            setUserInteracted(true);
        }
    };
    return (
        <>
        {/* <ThemeProvider> */}
            <div className="background-video-container">
                <div className="slideshow-banner-container">
                    <Suspense fallback={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</div>}>
                        <CarouselContainer />
                    </Suspense>
                    {/* <div style={{ position: 'absolute', width: '100%', top: '0px', right: '0', bottom: '0', left: '0', zIndex: '1', backgroundColor: 'rgba(0, 0, 0, 0.7)', height: '760px' }}>
                        <Container>
                            <div style={{ height: '100vh', justifyContent: 'flex-start', alignItems: 'center', display: 'flex'}}>
                                <Box sx={{ width: '50%', textAlign: 'left' }}>
                                    <h1 style={{ fontSize: '42px' }}>
                                        Technology & creative solution
                                    </h1>
                                    <p style={{ fontSize: '18px' }}>
                                        Providing resources for leading supplement, beverage, health, wellness and tech companies
                                    </p>
                                    <Button variant="outlined">More <ArrowForward/></Button>
                                </Box>
                            </div>
                        </Container>
                    </div> */}
                </div>
                {/* Video Banner Hidden due to slide show */}
                {/* {userInteracted ? (
                    <video autoPlay={true} muted loop style={{ width: '100%', marginTop: '90px' }}>
                        <source src={video} type="video/mp4" />
                    </video>
                ) : (
                    <div className="play-button" onClick={handlePlay}>
                        Click to play video
                    </div>
                )} */}
            </div>
            {/* </ThemeProvider> */}
        </>
    )
}

export default VideoBanner